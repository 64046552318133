import React, { ButtonHTMLAttributes, FC, useRef } from 'react';
import styled from 'styled-components';
import { variant as styleVariant } from 'styled-system';
import { useFocusRing, useButton } from 'react-aria';
import css, { SystemStyleObject } from '@styled-system/css';
import { AriaButtonProps } from '@react-types/button';
import { BaseButton } from '../Primitives';

type ComposedButton = ButtonHTMLAttributes<HTMLButtonElement> & AriaButtonProps;
export interface ButtonProps extends ComposedButton {
  /**
   * What style should it be, as defined in our figma component ref?
   */
  variant?:
    | 'primary'
    | 'primaryColored'
    | 'secondary'
    | 'tertiary'
    | 'ghost'
    | 'icon'
    | 'largeIcon'
    | 'likert'
    | 'simple'
    | 'paddle'
    | 'inverted';
  /**
   * How large should the button be?
   */
  buttonSize?: 'medium' | 'large' | 'unset' | 'auto';
  /**
   * used for composing this button
   */
  className?: string;
  showOutline?: boolean;
  delayPress?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
  isSelected?: boolean;
  isFocusVisible?: boolean;
  sx?: SystemStyleObject;
}

const buttonSizeVariant = ({ isFullWidth }) => {
  let variantConfig = {
    unset: {},
    large: {
      px: [6, 7, 7],
      py: [3, 6, 6],
    },
    medium: {
      px: [4, 5, 6],
      py: [2, '10px', 3],
    },
  } as const;
  if (isFullWidth) {
    variantConfig = Object.keys(variantConfig).reduce((acc, currKey) => {
      acc[currKey] = {
        ...variantConfig[currKey],
        width: '100%',
        minWidth: '0px',
      };
      return acc;
    }, {} as Record<`${keyof typeof variantConfig}`, any>);
  }
  return styleVariant({
    prop: '$buttonSize',
    variants: variantConfig,
  });
};

const tactileButtonStyles = ({
  edgeColor = 'hsla(191, 54%, 62%, 1)',
  castShadow = 'rgba(1,1,1, 0.15)',
  pressedCastShadow = 'rgba(1,1,1, 0.25)',
  disabled = false,
  isSelected = false,
  selectedColor = 'primary',
} = {}) => {
  const edgeHeight = '5px';
  const edgeHoverInset = '3px';
  const edgeHoverHeight = '3px';

  const pressedStyles = {
    transform: `translate(0, ${edgeHoverInset})`,
    '&::before': {
      boxShadow: `0 0 0 1px black, 0 0.5em 0 0 ${pressedCastShadow}`,
      transform: `translate3d(0, ${edgeHoverHeight}, -1em)`,
    },
  };
  let style: any = {
    transformStyle: 'preserve-3d',
    willChange: 'transform',
    backgroundColor: isSelected ? selectedColor : 'surface',
    '&::before': {
      zIndex: -1,
      pointerEvents: 'none',
      position: 'absolute',
      content: '""',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: edgeColor,
      borderRadius: 'inherit',
      boxShadow: t =>
        `0 0 0 1px ${t.colors.foreground}, 0 0.625em 0 0 ${castShadow}`,
      transform: `translate3d(0, ${edgeHeight}, -1em)`,
      transition:
        'transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1)',
    },
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        ...pressedStyles,
      },
    },

    '&:active': {
      transform: 'translate(0em, 0.5em)',
      '&::before': {
        boxShadow: `0 0 0 1px ${castShadow}, 0 0 ${castShadow}`,
        transform: 'translate3d(0, 0, -1em)',
      },
    },
  };
  if (disabled) {
    style = {
      ...style,
      ...{
        backgroundColor: 'surfaceAccent',
        color: 'dimText',
        borderColor: 'dimText',
        transform: `translate(0, ${edgeHoverInset})`,
        '&::before': {
          boxShadow: `0 0 0 1px black, 0 0.5em 0 0 ${pressedCastShadow}`,
          transform: `translate3d(0, ${edgeHoverHeight}, -1em)`,
        },
      },
    };
  }
  if (isSelected) {
    style = {
      ...style,
      ...pressedStyles,
    };
  }
  return style;
};

const buttonBaseStyle = ({ disabled, $isFocusVisible, showOutline = true }) =>
  css({
    color: 'text',
    display: 'grid',
    placeContent: 'center',
    boxShadow: t =>
      $isFocusVisible ? `0 0 0 2pt ${t.colors.foreground}` : 'none',
    fontFamily: 'body',
    pointerEvents: disabled ? 'none' : 'auto',
    cursor: disabled ? 'not-allowed' : 'pointer',
    '& > *': {
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
    // backgroundColor: 'transparent',
    border: t => (showOutline ? `1px solid ${t.colors.foreground}` : 'none'),
    borderRadius: '99px',
    transition:
      'transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1)',
  });

const buttonStyleVariant = ({ theme, disabled, isSelected }) => {
  const hoverStyles = {
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        color: 'surface',
        backgroundColor: 'foreground',
        '#icon': {
          backgroundColor: 'surface',
        },
      },
    },
  };
  const variantConfig = {
    primary: {
      ...tactileButtonStyles({
        disabled,
        edgeColor: theme.colors.primaryDarker,
      }),
    },
    primaryColored: {
      ...tactileButtonStyles({ disabled }),
      backgroundColor: 'primaryDark',
    },
    secondary: {
      ...hoverStyles,
      borderWidth: '2px',
      backgroundColor: 'transparent',

      px: 4,
      py: 2,
      ...(isSelected && {
        color: 'surface',
        backgroundColor: 'foreground',
      }),
    },
    tertiary: {
      ...tactileButtonStyles({
        disabled,
        isSelected,
        edgeColor: theme.colors.tertiaryAccent,
        selectedColor: theme.colors.primaryLightest,
        // castShadow: 'transparent',
        // pressedCastShadow: 'transparent',
      }),
      borderRadius: '8px',
    },
    likert: {
      ...tactileButtonStyles({ edgeColor: theme.colors.secondaryAccentDark }),
      backgroundColor: 'secondaryAccent',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: ['48px', '72px'],
      height: ['48px', '72px'],
    },
    icon: {
      ...hoverStyles,
      width: ['32px', '40px', '40px'],
      height: ['32px', '40px', '40px'],
      borderRadius: 8,
      borderColor: 'foreground',
    },
    largeIcon: {
      height: '32px',
      width: '32px',
      borderRadius: 8,
      borderColor: 'foreground',
    },
    simple: {},
    ghost: {
      // TODO: this does not follow standard hover
      border: 'none',
      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          backgroundColor: 'hsla(147, 20%, 24%, 0.1) ',
        },
      },
    },
    paddle: {
      ...tactileButtonStyles({ edgeColor: theme.colors.primaryDarker }),
      backgroundColor: theme.colors.primaryDark,
      borderRadius: 6,
    },
    inverted: {
      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          backgroundColor: 'surface',
          color: 'text',
        },
      },
      backgroundColor: 'text',
      color: 'surface',
    },
  };
  return styleVariant({
    prop: 'variant',
    variants: variantConfig,
  });
};

export const ButtonContentStyled = styled.span(
  buttonBaseStyle,
  buttonStyleVariant,
  buttonSizeVariant,
);

const Button: FC<ButtonProps> = props => {
  const ref = useRef<HTMLButtonElement>(null);
  const { isFocusVisible, focusProps } = useFocusRing();
  const customIsFocusVisible = props.isFocusVisible || isFocusVisible;
  const { buttonProps: buttonAriaProps } = useButton(props, ref);

  const {
    children,
    variant = 'primary',
    buttonSize = 'large',
    showOutline = true,
    isDisabled = false,
    isSelected,
    onPress, // the purpose here is to exclude onPress from delegated.
    // at this point, onPress has been translated into onClick and onKeydown by useButton
    // passing onPress into delegated is pointless
    ...delegated
  } = props;

  return (
    <BaseButton
      {...buttonAriaProps}
      {...delegated}
      {...focusProps}
      ref={ref}
      sx={{ ...delegated.sx, cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <ButtonContentStyled
        // https://styled-components.com/docs/api#transient-props
        isSelected={isSelected}
        $buttonSize={buttonSize}
        variant={variant}
        $isFocusVisible={customIsFocusVisible}
        showOutline={showOutline}
        disabled={isDisabled}
      >
        {children}
      </ButtonContentStyled>
    </BaseButton>
  );
};

export default Button;
