import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import defaultSystemTheme from './theme';

// These are the fonts that are included in Airtable.
// We originally used the Airtable values as Font-Faces, but this was causing some issues with certain language icons.
type FontList =
  | 'Fira Sans'
  | 'Luckiest Guy'
  | 'Manrope Variable'
  | 'Inter Variable'
  | 'Times';

type FontObjectType = {
  [key in FontList]: string;
};

const fontConstants: FontObjectType = {
  'Fira Sans': `'Fira Sans', sans-serif`,
  'Luckiest Guy': 'Luckiest Guy, -apple-system, BlinkMacSystemFont, Arial',
  'Manrope Variable':
    'Manrope Variable, -apple-system, BlinkMacSystemFont, Arial',
  'Inter Variable': `'Inter Variable', sans-serif`,
  Times: `Times`,
};

interface Props {
  children: ReactNode;
  // TODO: make this more specific
  customColors?: Record<string, unknown>;
  titleFont: FontList;
  bodyFont: FontList;
}

// TODO: the merging of default user theme with custom user theme that comes from airtable can be done in gatsby node itself
// TODO: Can change later to fit different themes
const CustomThemeProvider: FC<Props> = ({
  children,
  customColors,
  titleFont: funFont,
  bodyFont,
}) => {
  const appliedTheme = {
    ...defaultSystemTheme,
    colors: customColors || defaultSystemTheme.colors,
    fonts: {
      fun: fontConstants[funFont] ?? defaultSystemTheme.fonts.fun,
      body: fontConstants[bodyFont] ?? defaultSystemTheme.fonts.body,
    },
  };
  return <ThemeProvider theme={appliedTheme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
